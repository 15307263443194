import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify); // 全局引入Vuetify

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#1e496e',
                secondary: '#679AB1',
                accent: '#FFC107',
                error: '#FF5252'
            },
        },
    },
});
