import { baseURL } from './config.js';
import axios from 'axios';

const service = axios.create({
  baseURL: baseURL,
  timeout: 500000 // 请求超时时间
});

service.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['X-Token'] = token;
    }
    return config;
  },
  error => {
    console.log(error);
    Promise.reject(error);
  }
);

export default service;