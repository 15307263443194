<template>
  <v-container fluid>
    <div class="d-flex align-center">
      <v-text-field v-model="form.titleKeyword" label="按关键字查询" @keyup.enter="search" class="flex-grow-1 mr-2"/>
      <v-btn class="white--text" color="#1e496e" @click="search">查询</v-btn>
    </div>
    <div>
      <span v-for="a in authors" class="author-item" @click="author(a)">{{ a }}</span>
    </div>

    <div class="d-flex flex-column align-end mt-5 mb-5">
      <v-select v-model="form.sort" :items="sortOptions" label="排序" @change="sort" />
    </div>

    <div v-for="e in list" :key="e.id" class="item d-flex flex-row align-center">
      <v-btn :class="['love mr-5', e.love > 0 ? 'loved' : 'unloved']" @click="love(e)">
        <span><v-icon>mdi-thumb-up</v-icon></span>
        <span>{{ e.love }}</span>
      </v-btn>
      <div class="main d-flex flex-column pt-5 pb-5">
        <span class="author">{{ e.author + ' @' + e.site }}</span>
        <a :href="e.link" target="_blank" class="link mt-1">{{ e.title }}</a>
      </div>
    </div>
  </v-container>
</template>

<script>
import axios from 'axios';

let baseUrl = "https://qidafang.com"

export default {
  name: 'articles',
  data() {
    return {
      form: {
        titleKeyword: '',
        type: 'default',
        keyword: '',
        sort: 'love'
      },
      sortOptions: [
        { text: '最受欢迎', value: 'love' },
        { text: '最新收录', value: 'id' }
      ],
      list: [],
      authors: '煮酒正熟,辽东管宁,李天飞,与觚斋,娃娃鱼,赵学浩,杨文理,李靖岩,无为楼主,唯笑惟笑,白马将军上官迪,FFF团长,曹豫,释延德,杨沐梓,江宇舟,吕布姜维,卫迟,刀与笔,应弦吹笛,岳麓韶阳,哈士奇思维模拟器,安田秀彦,捣浆糊,欧小生,文若,静静的流浪猫,景谦,关2哥,芦苇,水镜,秦时明月,糗百小神仙,张小生,白菜,淮右咸鱼,夏至,刘寄奴,祁达方'
        .split(',')
    }
  },
  methods: {
    reset() {
      this.form.type = 'default'
      this.form.keyword = ''
      this.form.sort = 'love'
      this.form.titleKeyword = ''
      this.doSearch()
    },
    search() {
      this.form.type = 'title'
      this.form.keyword = this.form.titleKeyword
      this.form.sort = 'love'
      this.doSearch()
    },
    author(a) {
      this.form.type = 'author'
      this.form.keyword = a
      this.form.sort = 'love'
      this.form.titleKeyword = ''
      this.doSearch()
    },
    sort() {
      this.doSearch()
    },
    love(e) {
      axios.post(baseUrl + '/api/story/friendArticles/love/' + e.id)
        .then(response => {
          if (response.data.code === 'success') {
            e.love += 1;
            alert(response.data.data);
          } else {
            alert(response.data.data);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    doSearch() {
      if (this.form) {
        // 将查询表单记录到缓存
        localStorage.setItem('search_form', JSON.stringify(this.form))
      }

      var that = this

      let url = baseUrl + '/api/story/friendArticles/search?';
      url += 'titleKeyword=' + encodeURIComponent(this.form.titleKeyword);
      url += '&type=' + encodeURIComponent(this.form.type);
      url += '&keyword=' + encodeURIComponent(this.form.keyword);
      url += '&sort=' + encodeURIComponent(this.form.sort);

      axios.get(url)
        .then(response => {
          this.list = response.data;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  computed: {
  },
  created() {
    this.doSearch()
  }
}
</script>

<style scoped>

.item {
  border-top: #999 solid 1px;
}

.item .love {
  border: 1px solid #999;
  width: 50px;
  min-width: 50px;
  height: 50px;
  text-align: center;
  cursor: pointer;
  color: #999;
}

.item .love.loved {
  border-color: #1e496e;
  color: #1e496e;
}

.author-item {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 5px 10px;
  color: #fff;
  background-color: #1e496e;
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
  transition: background-color 0.2s ease-in-out;
}

.author-item:hover {
  background-color: #2c6280;
}

.author {
  font-weight: 600;
}
</style>