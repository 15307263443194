<template>
  <v-app>
    <!-- 导航栏 -->
    <v-app-bar app color="#1e496e" dark>
      <v-toolbar-title class="d-flex align-center">
        <v-app-bar-nav-icon @click="drawer = !drawer" class="hidden-md-and-up"></v-app-bar-nav-icon>
        <v-btn text href="/">
          <v-img alt="演义细读网" class="shrink mr-2" contain src="https://cdn-427studio.oss-cn-qingdao.aliyuncs.com/sanguo_header/11/%E5%88%98%E5%A4%87.jpg" transition="scale-transition"
            width="32" />
          <span class="title">演义细读网</span>
        </v-btn>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y class="hidden-sm-and-down" v-for="menu in menus" v-if="menu.show()" :key="menu.title">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on" class="hidden-sm-and-down">{{ menu.title }}</v-btn>
        </template>
        <v-list>
          <v-list-item :href="item.href" v-for="item in menu.items" :key="item.title" v-if="item.show()">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn v-if="!$store.getters.isLoggedIn" @click="loginDialog = true" text>
        登录
      </v-btn>
      <v-btn href="/me" v-else color="#EE964B">欢迎你，{{ $store.state.username }}</v-btn>
      <!--消息-->
      <v-btn icon v-if="$store.getters.isLoggedIn" class="hidden-sm-and-down" @click="markNotificationsAsRead">
        <v-badge color="red" overlap :content="unreadNotificationsCount" :value="unreadNotificationsCount" :offset-x="10"
          :offset-y="10">
          <v-icon>mdi-bell</v-icon>
        </v-badge>
      </v-btn>
    </v-app-bar>
    <!-- 侧边栏 -->
    <v-navigation-drawer v-model="drawer" app disable-resize-watcher>
      <v-list>
        <v-list-group no-action v-for="menu in menus" v-if="menu.show()" :key="menu.title">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ menu.title }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item :href="item.href" v-for="item in menu.items" :key="item.title" v-if="item.show()">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <!-- 主体 -->
    <v-main>
      <router-view></router-view>
    </v-main>
    <!-- 页脚 -->
    <v-footer app class="d-flex justify-center align-center">
      &copy; 演义细读微信群 2022-2024
    </v-footer>
    <v-dialog v-model="loginDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">登录</span>
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="username" label="用户名" required></v-text-field>
          <v-text-field v-model="password" label="密码" type="password" required></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="loginDialog = false">取消</v-btn>
          <v-btn color="blue darken-1" text @click="login">登录</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="3000">{{ snackbarText }}</v-snackbar>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">系统消息</v-card-title>
        <v-card-text>
          <v-list>
            <v-divider></v-divider>
            <v-list-item v-for="notification in notifications" :key="notification.id">
              <v-list-item-content>
                <v-list-item-title class="headline mb-1">{{ notification.title }}</v-list-item-title>
                <v-list-item-subtitle class="caption">{{ new Date(notification.addTime).toLocaleString()
                }}</v-list-item-subtitle>
                <p class="body-1">{{ notification.content }}</p>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">关闭</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import axios from 'axios';
import service from '@/utils/request';
import stompClient from '@/utils/websocket.js';

export default {
  name: 'App',
  data() {
    return {
      menus: [
        {
          title: '三国演义',
          show: () => true,
          items: [
            { href: '/read/嘉靖壬午本/1', title: '在线阅读', show: () => true },
            { href: 'https://qidafang.com/autologin?token=' + localStorage.getItem('token'), title: '高级阅读', show: () => this.$store.getters.isLoggedIn },
            { href: '/doc/links', title: '常用链接', show: () => true },
          ],
        },
        {
          title: '近期活动',
          show: () => true,
          items: [
            { href: '/vote/water_force_120', title: '水浒武评百二十回版', show: () => this.$store.getters.isLoggedIn },
            { href: '/vote/water_force', title: '水浒武评', show: () => this.$store.getters.isLoggedIn },
            { href: '/vote/intelligence', title: '演义智评', show: () => this.$store.getters.isLoggedIn },
            { href: '/vote/warrior_intelligence', title: '武将智评', show: () => this.$store.getters.isLoggedIn },
            { href: '/pk/intelligence', title: '智力PK', show: () => this.$store.getters.isLoggedIn },
            { href: '/result', title: '活动结果', show: () => true },
          ],
        },
        {
          title: '讨论区',
          show: () => this.$store.getters.isLoggedIn,
          items: [
            { href: 'https://bbs.sanguo.wiki/', title: '内部论坛', show: () => this.$store.getters.isLoggedIn },
            { href: '/talk', title: 'AI问答', show: () => this.$store.getters.isLoggedIn },
            { href: '/random', title: '随机阅读', show: () => this.$store.getters.isLoggedIn },
          ],
        },
        {
          title: '小游戏',
          show: () => this.$store.getters.isLoggedIn,
          items: [
            { href: 'https://battle.sanguo.wiki', title: '武将对战', show: () => this.$store.getters.isLoggedIn },
            { href: '/answer/1', title: '知识问答', show: () => true },
            { href: '/draw', title: '你画我猜', show: () => this.$store.getters.isLoggedIn },
          ],
        },
      ],
      drawer: false,
      username: '',
      password: '',
      loginDialog: false,
      snackbar: false,
      snackbarText: '',
      snackbarColor: 'success',
      notifications: [],
      dialog: false,
    }
  },
  computed: {
    isLoggedIn() {
      return !!localStorage.getItem('token');
    },
    readUrl() {
      return 'https://qidafang.com/autologin?token=' + localStorage.getItem('token');
    },
    unreadNotificationsCount() {
      return this.notifications.filter(notification => !notification.isRead).length;
    }
  },
  provide() {
    return {
      stompClient: stompClient,
      showMessage: this.showMessage
    };
  },
  methods: {
    login() {
      this.$store.dispatch('login', {
        username: this.username,
        password: this.password
      });
      this.loginDialog = false;
    },
    showMessage(message, color = 'success') {
      this.snackbarText = message;
      this.snackbarColor = color;
      this.snackbar = true;
    },
    getNotifications() {
      service.get('/wikiapi/notifications')
        .then(response => {
          this.notifications = response.data;
        })
        .catch(error => {
          console.error(error);
        });

      // 订阅消息
      stompClient.connect({}, (frame) => {
        stompClient.subscribe('/user/queue/notifications', (notification) => {
          const newNotification = JSON.parse(notification.body);
          this.notifications.unshift(newNotification);
        });
      });
    },
    markNotificationsAsRead() {
      this.dialog = true;
      this.notifications.forEach(notification => {
        if (!notification.isRead) {
          service.put(`/wikiapi/notifications/${notification.id}`)
            .then(() => {
              notification.isRead = true;
            })
            .catch(error => {
              console.error(error);
            });
        }
      });
    }
  },
  watch: {
    '$store.getters.isLoggedIn': {
      handler: function (isLoggedIn) {
        if (isLoggedIn) {
          this.getNotifications();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.$store.getters.isLoggedIn) {
      this.getNotifications();
    }

  }
}
</script>

<style>
.v-toolbar__content {
  padding-left: 0 !important;
}

.v-application--is-ltr .v-list-group--no-action>.v-list-group__items>.v-list-item {
  padding-left: 36px !important;
}

.ql-editor {
  min-height: 300px !important;
}</style>