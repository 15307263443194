import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    username: localStorage.getItem('username') || '',
    token: localStorage.getItem('token') || null
  },
  getters: {
    isLoggedIn: state => !!state.token
  },
  mutations: {
    login: (state, { username, token }) => {
      state.username = username;
      state.token = token;
      localStorage.setItem('username', username);
      localStorage.setItem('token', token);
    },
    logout: (state) => {
      state.username = '';
      state.token = null;
      localStorage.removeItem('username');
      localStorage.removeItem('token');
    }
  },
  actions: {
    async login({ commit }, { username, password }) {
      try {
        const response = await axios.post('https://qidafang.com/adminapi/user/login', { username, password });
        commit('login', { username, token: response.data.data.token });

        // 自动登录论坛
        const bbsRespose = await axios.get('https://qidafang.com/adminapi/user/bbsScript?token=' + response.data.data.token); // 异步请求论坛登录脚本
        const scriptStr = bbsRespose.data.data; // 获取脚本字符串
        this.dispatch('insertScript', scriptStr); // 将脚本插入页面
      } catch (error) {
        console.error(error);
      }
    },
    insertScript({ commit }, scriptString) {
      // 创建一个临时容器元素
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = scriptString;

      // 提取并添加以https开头的脚本标签
      const script = tempDiv.querySelector('script[src^="https"]');
      if (script) {
        const newScript = document.createElement('script');
        newScript.src = script.src;
        if (script.hasAttribute('reload')) {
          newScript.setAttribute('reload', script.getAttribute('reload'));
        }
        document.body.appendChild(newScript);
      }
    }
  },
  logout({ commit }) {
    commit('logout');
  }
})
